<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form
                    ? "Create New Email"
                    : "Update Email"
                }}
              </strong>
            </div>
            <b-row>
              <b-col sm="12">
                <strong>
                  List of available tokens
                </strong>
                <ul>
                  <li>Account.No</li>
                  <li>Account.Name</li>
                  <li>Account.BillingAddress</li>
                  <li>Account.BillingUnit</li>
                  <li>Account.BillingPostalCode</li>
                  <li>Account.TotalOutstandingBalance</li>
                  <li>Invoice.No</li>
                  <li>Invoice.TotalAmount</li>
                  <li>Invoice.DueDate</li>
                  <li>Invoice.InvoiceDate</li>
                </ul>
              </b-col>
            </b-row>
            <b-row>
              <BuildingDropdown :server-params="form" :form-group="true" v-if="$route.name == route.form"/>
              <b-col sm="6">
                <form-group :validator="$v.form.name"
                            label="Name"
                            :use-horizontal="false">
                  <b-form-input :disabled="$route.name != route.form" v-model="form.name" type="text" placeholder="Enter Name of Template" autocomplete="off">
                  </b-form-input>
                </form-group>
              </b-col>
              <b-col sm="6">
                <form-group :validator="$v.form.subject" label="Subject" :use-horizontal="false">
                  <b-form-input v-model="form.subject" type="text" placeholder="Enter Subject of Template"
                                autocomplete="off">
                  </b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.message" label="Message" :use-horizontal="false">
                  <vue-editor v-model="form.message" />
                </form-group>
              </b-col>
              <b-col sm="12">
                <b-form-file v-model="attachments"
                             multiple
                             placeholder="Attachments (optional)..."
                             @input="selectFile"
                             drop-placeholder="Drop file here..."></b-form-file>
              </b-col>
              <!--existing attachments-->
              <b-col sm="12" v-if="form.loadedAttachments.length">
                <ul>
                  <li v-for="item in form.loadedAttachments" style="margin-bottom: 5px">
                    {{ item.fileName }}
                    <span style="margin: 0 5px;"><b-button type="button" @click="handleAttachmentDownload(item)" variant="success" size="sm">Download</b-button></span>
                    <span><b-button type="button" @click="handleAttachmentRemove(item)" variant="danger" size="sm">Remove</b-button></span>
                  </li>
                </ul>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.bcc" label="BCC" :use-horizontal="false">
                  <b-form-input v-model="form.bcc" type="text" placeholder="Enter BCC" autocomplete="off">
                  </b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :use-horizontal="false">
                  <b-form-checkbox v-model="form.isActive">
                    Is Active ?
                  </b-form-checkbox>
                </form-group>
              </b-col>

            </b-row>
            <div slot="footer">
              <b-button size="sm" @click="$router.go(-1)"> Back to List </b-button>
              <b-button type="submit" size="sm" variant="success" class="float-right">
                {{ $route.name == route.form ? "Submit" : "Save Changes" }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  import { VueEditor } from "vue2-editor";
  import {
    required,
    email,
  } from "vuelidate/lib/validators";

  export default {
    data: () => ({
      route: {
        form: "EmailTemplateCreate",
        table: "EmailTemplate",
      },
      attachments: [],
      form: {
        name: null,
        subject: null,
        message: null,
        bcc: null,
        isActive: false,
        loadedAttachments: [],
      },
    }),
    validations: {
      form: {
        name: { required },
        subject: { required },
        message: { required },
        bcc: { required },
        // bcc: { required, email },
      },
    },
    created() {
      const self = this;
      if (self.$route.name != self.route.form) {
        self.get();
      }
    },
    components: {
      BuildingDropdown,
      VueEditor,
    },
    methods: {
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/EmailTemplate/${self.$route.params.id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                name: response.data.name,
                subject: response.data.subject,
                message: response.data.message,
                bcc: response.data.bcc,
                loadedAttachments: response.data.attachments,
                isActive: response.data.isActive,
              };
            }
            loader.hide();
          });
      },
      handleAttachmentRemove(item) {
        const self = this;
        self.$dialog
          .confirm("Are you sure you want to remove this attachment?", {
            okText: "Yes, Delete",
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch("apis/remove", {
                url: "/EmailTemplate/Delete/" + item.attachmentId,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });
                  this.get();
                }
              });
          });
      },
      handleAttachmentDownload(item) {
        const self = this;
        self.$store
          .dispatch("apis/download", {
            url: "/EmailTemplate/Download/" + item.attachmentId,
            params: self.serverParams,
          })
          .then((response) => {
            console.log(response);
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", item.fileName);
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
      selectFile() {
        const self = this;
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this Email Template. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "/EmailTemplate";
        } else {
          _confirmText = "You are about to update this Email Template. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          _url = `/EmailTemplate/${self.$route.params.id}`;
        }
        const formData = new FormData();
        formData.append("PostData", JSON.stringify(self.form));
        if (self.attachments.length) {
          for (let i = 0; i < self.attachments.length; i++) {
            formData.append('files', self.attachments[i]);
          }
        }
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: formData,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },

    }

  };
</script>
